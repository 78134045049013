import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Signup from "../auth/signup/Signup";
import axios from "axios";
import {
  FormControl,
  TextField,
  Checkbox,
  Collapse,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
  IconButton,
  InputAdornment,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { tableCellClasses } from "@mui/material/TableCell";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { toast, ToastContainer } from "react-toastify";
import roleService from "../roles/roleService";
import { MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Rowing, Visibility, VisibilityOff } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ReactComponent as UserIcon } from "../assets/icons/UserIcon.svg";
import { ReactComponent as Hemberger } from "../assets/icons/Hemberger.svg";
import Sidebar from "../sidenav/Sidenav";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ManageEmployeeMobile = () => {
  /* on Successfull Update/Add Dialog */
  const [openSuccessfulMessage, setOpenSuccessfulMessage] =
    React.useState(false);
  const handleClose = () => {
    setOpenSuccessfulMessage(false);
  };
  const [employees, setEmployees] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(7);  const [resetDialog, setResetDialog] = useState(false);
  const [userConfirmation, setUserConfirmation] = React.useState(false);

  const [formValues, setFormValues] = useState({
    id: "",
    name: "",
    role: "",
    email: "",
    phone: "",
    dob: "",
  });

  const formatDate = (utcDateString) => {
    const dobUTC = utcDateString;
    const dobDate = new Date(dobUTC);
    return dobDate;
    // const options = {
    //   day: "2-digit",
    //   month: "2-digit",
    //   year: "numeric",
    // };
    // return dobDate.toLocaleDateString("en-IN", options);
  };

  const [resetPassword, SetResetPassword] = useState({
    id: "",
    password: "",
    confirmPassword: "",
  });

  const handleUpdate = (employeeId) => {
    const selected = employees.find(
      (employee) => employee.emp_id === employeeId
    );
    console.log(
      new Date(formatDate(selected.dob)),
      formatDate(selected.dob),
      " handleUpdate function"
    );
    setSelectedEmployee(selected);
    setFormValues({
      id: selected.emp_id,
      name: selected.emp_name,
      role: selected.role,
      email: selected.email,
      phone: selected.phone,
      dob: formatDate(selected.dob),
    });
    setUpdateDialog(true);
  };

  const handleResetPassword = (employeeId) => {
    const selected = employees.find(
      (employee) => employee.emp_id === employeeId
    );
    console.log(selected, " selected employee");
    setSelectedEmployee(selected);
    SetResetPassword({
      id: employeeId,
    });
    setResetDialog(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialog(false);
  };

  const handleDateChange = (value) => {
    console.log(dayjs(value).format("DD-MM-YYYY"), " handleDateChange"); // '16-12-2001'
    const formattedDate = dayjs(value).format("DD/MM/YYYY");
    setFormValues((prevValues) => ({ ...prevValues, dob: formattedDate }));
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value, " hello there");
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleUpdateEmployee = async () => {
    let { id, name, role, email, phone, dob } = formValues;
    // const dobFormat = new Date(dob).toISOString().slice(0, 10);
    console.log(name, role, email, phone, dob, " form values");
    if (!id || !role || !name || !email || !phone || !dob) {
      toast.error("All fields are required");
      console.error("All fields are required");
      return;
    }
    const formattedDate = dayjs(dob).format('DD/MM/YYYY');
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/update/${id}`,
        {
          name,
          role,
          email,
          phone,
          dob,
          dob:formattedDate
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // toast.success('Employee updated successfully');
        fetchEmployees();
        setUpdateDialog(false);
        setOpenSuccessfulMessage(true);
      } else {
        toast.error("Error updating employee: ", response.data.message);
      }
    } catch (error) {
      toast.error("Error updating employee: ", error);
    }
  };

  const handleResetButtonClick = async () => {
    const { id, password } = resetPassword;
    if (!password || !id) {
      toast.error("All fields are required");
      return;
    }
    // setOpenSuccessfulMessage(true); // demo
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/reset/${id}`,
        {
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchEmployees();
        setResetDialog(false);
        setOpenSuccessfulMessage(true);
        // toast.success("Password reset successfully")
      } else {
        toast.error("Error updating employee: ", response.data.message);
      }
    } catch (error) {
      toast.error("Error updating employee: ", error);
    }
  };

  const [token, setToken] = useLocalStorage("token", null);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setEmployees(response.data.data);
      } else {
        console.error("Error fetching employees: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching employees: ", error);
    }
  };

  const fetchRoles = async () => {
    try {
      const activeRoles = await roleService.getRoles(token);
      setRoles(activeRoles);
    } catch (error) {
      console.error("Error fetching roles:", error.response);
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchRoles();
  }, [token]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setFormValues({
      name: "",
      role: "",
      email: "",
      phone: "",
      dob: "",
      password: "",
      confirmPassword: "",
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setResetDialog(false);
    SetResetPassword({
      password: "",
      confirmPassword: "",
    });
    fetchEmployees();
  };

  const handleAddNewEmployee = (newEmployee) => {
    // console.log(formValues, " new employee");
    let { name, role, email, phone, dob, password } = formValues;
    console.log(name, role, email, phone, dob, password, " form values");
    if (
      !name ||
      !role ||
      !email ||
      !phone ||
      !dob ||
      !password ||
      !formValues.confirmPassword
    ) {
      toast.error("All fields are required");
      return;
    } else if (password !== formValues.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    setEmployees((prevEmployees) => [...prevEmployees, newEmployee]);
    handleCloseDialog();
    setFormValues({});
  };

  const [open, setOpen] = React.useState(false);
  const handleDelete = async (employeeId) => {
    setOpen(true);
    const selected = employees.find(
      (employee) => employee.emp_id === employeeId
    );
    setSelectedEmployee(selected);
  };
  const handleConfirmDelete = async () => {
    console.log(selectedEmployee, " selected employee");
    setOpen(false);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/delete/${selectedEmployee.emp_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Employee deleted successfully");
        fetchEmployees();
      } else {
        toast.error("Error deleting employee: ", response.data.message);
      }
    } catch (error) {
      toast.error("Error deleting employee: ", error);
    }
  };

  const handleResetFormChange = (e) => {
    const { name, value } = e.target;
    SetResetPassword((prevValues) => ({ ...prevValues, [name]: value }));
  };

  /* MUI Table */
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    if (!isAuthorized) {
      return (
        <div className="container-fluid mt-5">
          <h2>You are unauthorized to access this page</h2>
        </div>
      );
    }

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row">
            <div>{row?.emp_name}</div>
            {/* <div>{row?.username}</div> */}
          </TableCell>
          <TableCell align="right">
            <div>{row?.role}</div>
            {/* <div>{formatDate(row?.created_at).time}</div> */}
          </TableCell>
          <TableCell style={{ textAlign: "right" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              style={{ color: "black" }}
              onClick={() => setOpen(!open)}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            "& td": { border: 0, height: open ? "auto" : 0 },
            borderBottom: "1px solid black",
          }}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, borderTop: "none" }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "10px",
                  margin: "4px 0",
                }}
              >
                <div style={{ flex: "1" }}>Action: </div>
                <div>
                  <Button
                    className="mr-2"
                    onClick={() => handleUpdate(row.emp_id)}
                    style={{
                      fontSize: "0.563rem",
                      background: "#CBEBF6",
                      color: "black",
                      boxShadow: "2px 2px 4px 0px #AEAEC080",
                      textTransform: "none",
                      width: "5rem",
                      height: "1.25rem",
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    onClick={() => handleResetPassword(row.emp_id)}
                    className="mr-2"
                    style={{
                      padding: "0",
                      fontSize: "0.563rem",
                      background: "#FCF6BC",
                      color: "black",
                      boxShadow: "2px 2px 4px 0px #AEAEC080",
                      textTransform: "none",
                      width: "5rem",
                      height: "1.25rem",
                    }}
                  >
                    Reset Password
                  </Button>
                  <Button
                    onClick={() => handleDelete(row.emp_id)}
                    style={{
                      fontSize: "0.563rem",
                      background: "#FFD6D2",
                      color: "black",
                      boxShadow: "2px 2px 4px 0px #AEAEC080",
                      textTransform: "none",
                      width: "5rem",
                      height: "1.25rem",
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "2.5rem 0.75rem 1.25rem 0.75rem",
        }}
      >
        <div
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            width: "36px",
            height: "36px",
            borderRadius: "18px",
            background: "#EAF1F7",
          }}
        >
          <Hemberger />
        </div>
        <Link to={"/profile"}>
          <UserIcon style={{ cursor: "pointer" }} />
        </Link>
      </div>
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={closeSidebar}
        setLogoutDialog={setLogoutDialog}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0rem 0.75rem 1.5rem 0.75rem",
        }}
      >
        <h4>Employee</h4>
        <Button
          onClick={handleOpenDialog}
          className="button-two"
          style={{ width: "6rem", height: "1.75rem", background: "#37446F" }}
        >
          Add
        </Button>
      </div>

      {/* MUI Table */}
      <Table
        aria-label="collapsible table"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableHead
          style={{
            height: "44px",
            borderRadius: "4px !important",
            background: "rgba(234, 241, 247, 1)",
            boxShadow: "2px 2px 4px 0px rgba(174, 174, 192, 0.5)",
          }}
        >
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {employees?.map((employee, ind) => (
            <Row key={ind} row={employee} />
          ))}
        </TableBody>
      </Table>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        style={{ margin: "auto" }}
      >
        <DialogTitle
          style={{ fontWeight: "600", fontSize: "1.125rem", textAlign: "left" }}
        >
          Add New Employee
        </DialogTitle>
        {/* <p style={{ marginTop: '-0.813rem', textAlign: 'center', fontWeight: '600', padding: '0', margin: '0' }}>Admin Signup</p> */}
        <Signup
          onAddEmployee={handleAddNewEmployee}
          handleCloseDialog={handleCloseDialog}
        />
      </Dialog>

      <Dialog open={updateDialog} onClose={handleUpdateDialogClose}>
        <DialogTitle style={{ fontWeight: "600" }}>Update Employee</DialogTitle>
        <form className="m-2" style={{ padding: "0px 14px 14px 14px" }}>
          <TextField
            className="mb-3"
            type="text"
            label="Employee Name"
            name="name"
            variant="outlined"
            value={formValues.name}
            onChange={handleFormChange}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.MuiInputBase-root fieldset": {
                  borderColor: "white",
                },
              },
            }}
            InputProps={{
              style: {
                boxShadow:
                  "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
              },
            }}
            InputLabelProps={{
              sx: { "&.Mui-focused": { color: "#A8A8A8" } },
              required: false,
            }}
          />
          <TextField
            className="mb-3"
            type="email"
            label="Email"
            name="email"
            variant="outlined"
            value={formValues.email}
            onChange={handleFormChange}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.MuiInputBase-root fieldset": {
                  borderColor: "white",
                },
              },
            }}
            InputProps={{
              style: {
                boxShadow:
                  "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
              },
            }}
            InputLabelProps={{
              sx: { "&.Mui-focused": { color: "#A8A8A8" } },
              required: false,
            }}
          />
          <br />
          <FormControl
            className="mb-3"
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                  boxShadow: "none",
                },
              },
            }}
            style={{
              boxShadow:
                "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
            }}
          >
            <InputLabel
              id="demo-simple-select-standard-label"
              style={{ color: "#A8A8A8" }}
            >
              Role
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="role"
              value={formValues.role}
              onChange={handleFormChange}
              label="Role"
              // input={<OutlinedInput label="Role" />}
              // renderValue={(selected) => selected.join(', ')}
              // MenuProps={MenuProps}
            >
              {roles?.map((roles) => (
                <MenuItem key={roles.role} value={roles.role}>
                  <ListItemText primary={roles.role} />
                  {/* <Checkbox checked={personName.indexOf(roles.role) > -1} /> */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <TextField
            className="mb-3"
            type="text"
            label="Mobile"
            name="phone"
            variant="outlined"
            value={formValues.phone}
            onChange={handleFormChange}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.MuiInputBase-root fieldset": {
                  borderColor: "white",
                },
              },
            }}
            InputProps={{
              style: {
                boxShadow:
                  "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
              },
            }}
            InputLabelProps={{
              sx: { "&.Mui-focused": { color: "#A8A8A8" } },
              required: false,
            }}
          />
          <FormControl
            fullWidth
            className="mb-3"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                  boxShadow: "none",
                },
              },
              "&.Mui-focused": { color: "#A8A8A8" },
            }}
            style={{
              boxShadow:
                "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                value={dayjs(formValues.dob)}
                label="Date of Birth"
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField {...params} error={false} />
                )}
              />
            </LocalizationProvider>
          </FormControl>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "0.75rem",
            }}
          >
            <Button
              onClick={() => setUpdateDialog(false)}
              className="formBtnMobile"
              style={{
                background: "none",
                color: "black",
                border: "2px solid #37446F",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpdateEmployee}
              className="formBtnMobile"
              style={{ background: "#37446F", color: "white" }}
            >
              Update
            </Button>
          </div>
        </form>
      </Dialog>

      {/* Reset Employee Password */}
      <Dialog open={resetDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ fontWeight: "600" }}>
          Reset Employee Password
        </DialogTitle>
        <form className="m-3">
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                  boxShadow: "none",
                },
              },
              marginBottom: "1.25rem",
              boxShadow:
                "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
            }}
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{ "&.Mui-focused": { color: "#A8A8A8" } }}
            >
              Password
            </InputLabel>
            <OutlinedInput
              fullWidth={true}
              name="password"
              onChange={handleResetFormChange}
              sx={{ backgroundColor: "transparent" }}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    style={{ background: "none" }}
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <br />
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                  boxShadow: "none",
                },
              },
              marginBottom: "1.25rem",
              boxShadow:
                "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
            }}
          >
            <InputLabel
              htmlFor="outlined-adornment-password1"
              sx={{ "&.Mui-focused": { color: "#A8A8A8" } }}
            >
              Confirm Password
            </InputLabel>
            <OutlinedInput
              fullWidth={true}
              name="confirmPassword"
              onChange={handleResetFormChange}
              sx={{ backgroundColor: "transparent" }}
              id="outlined-adornment-password1"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    style={{ background: "none" }}
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
          </FormControl>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => setResetDialog(false)}
              fullWidth
              className="formBtnMobile mr-2"
              style={{
                background: "none",
                border: "2px solid #37446F",
                color: "black",
              }}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              onClick={handleResetButtonClick}
              className="formBtnMobile"
              disabled={
                !resetPassword.confirmPassword ||
                resetPassword.confirmPassword !== resetPassword.password
              }
              style={{ background: "#37446F", color: "white" }}
            >
              Reset password
            </Button>
          </div>
        </form>
      </Dialog>

      {/* Successfull Message: Dialog Box */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openSuccessfulMessage}
      >
        <div style={{ marginBottom: "2rem" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            width: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "3rem",
            paddingTop: "0",
          }}
        >
          <CheckCircleIcon
            style={{ color: "green", fontSize: "2rem", margin: "1rem" }}
          />
          <h4>{resetDialog ? "Reset" : "Updated"} Successful!</h4>
          <p style={{ textAlign: "center", fontSize: "0.75rem" }}>
            {resetDialog
              ? "Password has been successfully reset."
              : "The employee details has been successfully updated."}
          </p>
        </div>
      </BootstrapDialog>

      {/* Delete Dialog Box */}
      <Dialog
        open={open}
        onClose={(prev) => !prev}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ textAlign: "center" }}
      >
        <ErrorOutlineIcon
          style={{ color: "red", fontSize: "32px", margin: "12px auto" }}
        />
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontSize: "18px", fontWeight: "500", lineHeight: "0" }}
        >
          {"Are you Sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "12px" }}
          >
            You won't be able to revert this!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            style={{
              textTransform: "none",
              width: "120px",
              height: "36px",
              border: "2.03px solid #37446F",
              borderRadius: "5.85px",
              background: "none",
              color: "black",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            style={{
              textTransform: "none",
              width: "120px",
              height: "36px",
              borderRadius: "5.85px",
              background: "#37446F",
              color: "white",
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManageEmployeeMobile;
