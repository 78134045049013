import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import styles from "./registerCamp.module.css";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { TablePagination } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import isDesktop from "../isDesktop";
import RegisterCampMobile from "./registerCampMobile";

const Subscriptions = () => {
  const isDesktopDevice = isDesktop();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [token, setToken] = useLocalStorage('token', null);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(24);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [updateDialog, setUpdateDialog] = useState(false);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedUsers = filteredUsers.slice(startIndex, endIndex);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/contact-us/get/all/register`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data.data, " got all users");
        // const reversedUsers = response.data.data.reverse(); // Reverse the array of users
        const actualUsers = response.data.data;
        const sortedUsers = [...actualUsers].sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        });
        setUsers(sortedUsers);
        setFilteredUsers(sortedUsers);
      } else {
        console.error("Error fetching users: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    if (isDesktopDevice) {
      fetchUsers();
    }
  }, [token]);

  // Function to filter users based on search query
  const filterUsers = (query) => {
    if (!query) {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(
        (user) =>
          user.name.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase()) ||
          user.class.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  };

  useEffect(() => {
    isDesktopDevice && filterUsers(searchQuery);
  }, [searchQuery]);

  if (!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2 style={{ marginTop: '5%' }}>You are unauthorized to access this page</h2>
      </div>
    );
  }

  function formatDate(dateTimeString) {
    let date = new Date(dateTimeString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let formattedDate = `${day}.${month}.${year}`;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let formattedTime = `${hours}:${minutes}${AmOrPm}`;
    // return { time: formattedTime, date: formattedDate };
    return `${formattedDate} ${formattedTime}`;
  }

  if(!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2 style={{marginTop: '2rem'}}>You are unauthorized to access this page</h2>
      </div>
    )
  }

  return (
    <>
      {
        isDesktopDevice ? (
          <div className="container-fluid" style={{ paddingTop: '4rem' }}>
            <div className="align-center-all full-height">
              <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                <CardContent>
                  <div className={styles.header}>
                    <h2 style={{ marginRight: "1rem" }} className="page-header">
                      Registrations
                    </h2>
                    <div className={styles.headerRight}>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                      <TextField
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                              boxShadow: 'none',
                            },
                          },
                        }}
                        InputProps={{
                          style: {
                            height: '2.564vw',
                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'

                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <ToastContainer />
                  <table className={styles.mainTable}>
                    <thead className={styles.tableHead}>
                      <tr>
                        <th style={{ textAlign: 'center', width: '6.538vw' }}>S. No.</th>
                        <th style={{ width: '18.718vw', textAlign: 'left' }}>Name</th>
                        <th style={{ width: '20.513vw', textAlign: 'left' }}>Email</th>
                        <th style={{ textAlign: 'center', width: '15.641vw' }}>Mobile</th>
                        <th style={{ textAlign: 'center' }}>Date & Time</th>
                        <th style={{ textAlign: 'center', paddingLeft: '5.128vw' }}>Class</th>
                      </tr>
                    </thead>
                    <tbody >
                      {displayedUsers.map((user, idx) => (
                        <tr key={user.user_id} className={styles.tableBody}>
                          <td>{idx + 1}</td>
                          <td style={{ textAlign: 'left' }}>{user.name} <br /> <div style={{ fontSize: '0.897vw' }}> {user.username} </div></td>
                          <td style={{ textAlign: 'left' }}>{user.email}</td>
                          <td>{user.phone}</td>
                          <td style={{ width: '8vw' }}>{formatDate(user.created_at)}</td>
                          <td style={{ paddingLeft: '5.3vw' }}>{user.class}</td>
                        </tr>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            </div>
          </div>
        ) : (
          <RegisterCampMobile />
        )
      }
    </>
  );
};

export default Subscriptions;
