import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import Sidebar from "../sidenav/Sidenav";

import { useLocalStorage } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";

function formatDateAndTime(date) {
  date = new Date(date);
  let ISTDate = new Date(date.getTime());
  let day = ISTDate.getDate();
  let month = ISTDate.getMonth() + 1;
  let year = ISTDate.getFullYear();
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;
  let formattedDate = `${day}.${month}.${year}`;

  let hours = ISTDate.getHours();
  let minutes = ISTDate.getMinutes();
  let AmOrPm = hours >= 12 ? 'pm' : 'am';
  hours = (hours % 12) || 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let formattedTime = `${hours}:${minutes}${AmOrPm}`;
  return { time: formattedTime, date: formattedDate };
}
/* MUI Table */
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <div>{row.name}</div>
          <div>{row.username}</div>
        </TableCell>
        <TableCell align="right">
          <div>{formatDateAndTime(row.created_at).date}</div>
          <div>{formatDateAndTime(row.created_at).time}</div>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            style={{ color: 'black' }}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit  >
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 150px', rowGap: '6px', fontSize: '10px', marginTop: '4px', marginBottom: '4px' }}>
              <div>Email: {row.email}</div>
              <div>Mobile: {row.phone}</div>
              <div>Message: {row.message}</div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const InquiriesMobile = () => {
  const [inquiries, setInquiries] = useState([]);
  const [filteredInquiries, setFilteredInquiries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [toolTipState, setToolTipState] = useState([false, false]);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(2);
  const [token, setToken] = useLocalStorage('token', null);

  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/contact-us`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          const sortedData = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          const temp = new Array(sortedData.length).fill(false);
          setToolTipState(temp);
          setInquiries(sortedData);
          setFilteredInquiries(sortedData);
        } else {
          console.error("Error fetching inquiries: ", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching inquiries: ", error);
      }
    };

    fetchInquiries();
  }, [token]);

  useEffect(() => {
    applyFilters();
  }, [searchQuery, fromDate, toDate]);

  const applyFilters = () => {
    const filteredData = inquiries.filter((inquiry) => {
      const { name, email, phone, created_at } = inquiry;
      const query = searchQuery.toLowerCase();
      const inquiryDate = new Date(created_at);
      const isWithinDateRange =
        (!fromDate || inquiryDate >= new Date(fromDate)) &&
        (!toDate || inquiryDate <= new Date(toDate));
      return (
        (name.toLowerCase().includes(query) ||
          email.toLowerCase().includes(query) ||
          phone.toLowerCase().includes(query)) &&
        isWithinDateRange
      );
    });

    setFilteredInquiries(filteredData);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  if (!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2>You are unauthorized to access this page</h2>
      </div>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to="/profile">
        <UserIcon style={{ cursor: 'pointer' }} />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />

      <div className="container-fluid">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <h4 style={{ marginBottom: '0' }}>Enquiries</h4>
          <TextField
            style={{ marginRight: "0" }}
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
            placeholder="Search"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              },
            }}
            InputProps={{
              style: {
                width: '8.75rem',
                height: '1.75rem',
                paddingRight: '0.2rem',
                boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
              },
              endAdornment: <SearchIcon />,
            }}
            margin="normal"
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '8px' }}>
          <TextField
            label="From Date"
            type="date"
            value={fromDate}
            onChange={handleFromDateChange}
            InputProps={{
              style: {
                width: '8.9rem',
                height: '1.75rem'
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            style={{
              marginRight: "1vw"
            }}
          />
          <TextField
            label="To Date"
            type="date"
            value={toDate}
            onChange={handleToDateChange}
            InputProps={{
              style: {
                width: '8.9rem',
                height: '1.75rem'
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </div>
        <Table aria-label="collapsible table" sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none"
          }
        }}>
          <TableHead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
            <TableRow>
              <TableCell>Name & Class</TableCell>
              <TableCell align="right">Date & Time</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInquiries?.map((inquiry) => (
              <Row key={inquiry.attempt_id} row={inquiry} />
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default InquiriesMobile;