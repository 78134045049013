import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { InputLabel } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import { Link } from "react-router-dom";
import Sidebar from "../sidenav/Sidenav";
import GreenTickDialog from "../Components/MobileDialog/GreenTickDialog";

const UserCartMobile = () => {
  /* on Successfull Update/Add Dialog */
  const [isSuccessfullAddCalled, setIsSuccessfullAddCalled] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const handleClose = () => {
    setOpenSuccessMessage(false);
    setIsSuccessfullAddCalled(false);
  };
  const [formData, setFormData] = useState({
    skill: [],
    specialization: [],
    userId: "",
    discount: "",
  });

  const [skills, setSkills] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [users, setUsers] = useState([]);

  const [token, setToken] = useLocalStorage('token', null);
  const empId = localStorage.getItem("userId");
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(13);
  const [discount, setDiscount] = useState();
  const [skillFees, setSkillFees] = useState({}); // State to hold skill fees

  const [totalBill, setTotalBill] = useState(0);
  const [finalBill, setFinalBill] = useState(0);

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };

  const fetchSpecializations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/specialization/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )


      if (response.status === 200) {
        console.log(response.data.data);
        setSpecialization(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching specialization data", error);

    }
  }

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setUsers(response.data.data);
      } else {
        console.error("Error fetching users: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    if (token && token !== "null") {
      fetchSkills();
      fetchUsers();
      fetchSpecializations()
    }
  }, [token]);

  useEffect(() => {
    // Calculate total fee when formData.skill changes
    const calculateTotalFee = () => {
      console.log(formData);

      let totalFee = 0;
      formData?.skill?.forEach((skillId) => {
        const selectedSkill = skills.find((skill) => skill.skill_id === skillId);
        if (selectedSkill) {
          // Add skill fee to total fee
          totalFee += selectedSkill.discounted_fee;
        }
      });
      formData?.specialization?.forEach((spec_id) => {
        const selectedSpecialization = specialization.find((specs) => { return specs.specialization_id === spec_id })
        if (selectedSpecialization) {
          totalFee += selectedSpecialization.discounted_fee
        }
      })
      setTotalBill(totalFee);
    };

    calculateTotalFee();
  }, [formData.skill, skills, formData.specialization]);

  useEffect(() => {
    // Calculate final bill when formData.discount or totalBill changes
    const calculateFinalBill = () => {
      const discount = formData.discount || 0; // Ensure discount is not null
      const finalAmount = totalBill - discount;
      setFinalBill(finalAmount >= 0 ? finalAmount : 0); // Ensure final amount is not negative
    };

    calculateFinalBill();
  }, [formData.discount, totalBill]);

  const handleChange = (event, value, name) => {
    let newValue;
    console.log(name, value, event, " handleChange");
    if (name === "userId") {
      // For the userId field, store user_id
      newValue = value ? value.map((item) => item.user_id) : null;
    } else if (name === "discount") {
      // For the discount field, store the entered value directly
      newValue = value ? parseInt(value, 10) : null;
    } else if (name === 'skill') {
      // For other fields (e.g., skill), store their IDs
      newValue = value ? value.map((item) => item.skill_id) : null;
    } else {
      newValue = value ? value.map((item) => item.specialization_id) : null;
    }
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name || name]: newValue,
    }));
  };

  const handleSubmit = async () => {
    const { skill, discount, userId, specialization } = formData;
    console.log("handle submit called");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/enrollment/add-to-cart`, {
        user_id: userId,
        skill_ids: skill,
        discount: parseInt(discount),
        specialization_ids: specialization,
        emp_id: empId
      });

      if (response.status === 201) {
        console.log("Items added to cart successfully");
        // toast.success("Items added to cart successfully")
        setOpenSuccessMessage(true);
        // Reset form data
        handleCancel();
      } else {
        console.error("Error adding items to cart: ", response.data.message);
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error("Error adding items to cart: ", error);
      toast.error(error.response.data.message)
    } 
  };
  const [resetInputField, setResetInputField] = useState(false);
  const handleCancel = () => {
    // Reset form data on cancel
    setFormData({
      skill: [],
      specialization: [],
      userId: "",
      discount: "",
    });
    setResetInputField((prev) => !prev);
    setTotalBill(0)
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  if (!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2 style={{marginTop: '5%'}}>You are unauthorized to access this page</h2>
      </div>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to="/profile">
          <UserIcon style={{ cursor: 'pointer' }} />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <h4 style={{ paddingLeft: '0.938rem' }}>User Cart</h4>
      <h5 style={{ textAlign: 'center' }}>Add to Cart</h5>
      <div className="container-fluid">

        <form onSubmit={handleSubmit}>
          {/* Users */}
          <>
            <Autocomplete
              key={resetInputField}
              multiple
              options={users}
              getOptionLabel={(option) => option.username}
              onChange={(event, value) => handleChange(event, value, "userId")}
              renderInput={(params) => (
                <TextField
                  id="userId"
                  {...params}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Username"
                  className='customTextfieldMobile'
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    }
                  }}
                  InputLabelProps={{
                    sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                    required: false
                  }}
                />
              )}
            />
          </>
          {/* Specialization */}
          <>
            <Autocomplete
              key={resetInputField}
              multiple
              disableCloseOnSelect={true}
              options={specialization}
              getOptionLabel={(option) => option.specialization_name}
              onChange={(event, value) => handleChange(event, value, "specialization")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  focuscolor='black'
                  label="Specialization"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  className='customTextfieldMobile'
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    }
                  }}
                  InputLabelProps={{
                    sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                    required: false
                  }}
                />
              )}
            />
          </>

          {/* Skills */}
          <>
            <Autocomplete
              key={resetInputField}
              multiple
              disableCloseOnSelect={true}
              options={skills}
              getOptionLabel={(option) => option.skill_name}
              onChange={(event, value) => handleChange(event, value, "skill")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  focuscolor='black'
                  label="Skills"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  className='customTextfieldMobile'
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    }
                  }}
                  InputLabelProps={{
                    sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                    required: false
                  }}
                />
              )}
            />
          </>
          {/* Discount */}
          <>
            <TextField
              key={resetInputField}
              className='customTextfieldMobile'
              label="Discount"
              type="number"
              id="discound"
              value={formData.discount}
              onChange={(event) =>
                handleChange(event, event.target.value, "discount")
              }
              fullWidth
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                }
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false
              }}
            />
          </>
        </form>
        <div className="billContainer">
          <div className="billTop" style={{ border: '1px solid #37446F' }}>
            <div className="billTitles">
              <div>Item Total</div>
              <div>Additional Discount</div>
            </div>
            <div className="billAmount">

              <div>{totalBill}</div>
              <div>-{formData.discount}</div>
            </div>
          </div>
          <div className="billBottom" style={{ border: '1px solid #37446F' }}>
            <div>Total</div>
            <div>{finalBill}</div>
          </div>
        </div>

        {/* Actions */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: '1.25rem'
          }}
        >
            <Button
              variant="contained"
              type="submit"
              onClick={handleCancel}
              style={{ flex: '1',height: '2.25rem', background: 'none', border: '2.03px solid #37446F', color: 'black', marginRight: '8px', textTransform: 'none' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              style={{ flex: '1', background: '#37446F', height: '2.25rem', color: 'white', textTransform: 'none' }}
            >
              Add to Cart
            </Button>
            <GreenTickDialog 
              subject={"User Cart"}
              openSuccessMessage={openSuccessMessage}
              handleClose={handleClose}
              massage1={isSuccessfullAddCalled ? "" : "Add to Cart"}
              massage2={isSuccessfullAddCalled ? "" : "updated"}
            />
        </div>
      </div>
    </>
  );
};

export default UserCartMobile;