import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import service from "../stage/stageService";
import { ReactComponent as Hemberger } from "../assets/icons/Hemberger.svg";
import { ReactComponent as UserIcon } from "../assets/icons/UserIcon.svg";
import {
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  TextField,
  Button,
  Card,
  CardContent,
  OutlinedInput,
  Checkbox,
  ListItemText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { tableCellClasses } from "@mui/material/TableCell";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import axios from "axios";
import { useLocalStorage } from "@uidotdev/usehooks";
import styles from "./BattleReportMobile.module.css";
import { toast } from "react-toastify";
import Sidebar from "../sidenav/Sidenav";

function formatDateAndTime(date) {
  date = new Date(date);
  let ISTDate = new Date(date.getTime());

  let day = ISTDate.getDate();
  day = day < 10 ? '0' + day : day;

  // Format the time in HH.MM am/pm
  let hours = ISTDate.getHours();
  let minutes = ISTDate.getMinutes();
  let AmOrPm = hours >= 12 ? 'pm' : 'am';
  hours = (hours % 12) || 12; // Convert to 12-hour format
  minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero to minutes if needed
  let formattedTime = `${hours}:${minutes}${AmOrPm}`;
  return formattedTime;
}
/* MUI Table */
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <div>{row.name}</div>
          <div>{row.user_class}</div>
        </TableCell>
        <TableCell align="right">
          <div>{formatDateAndTime(row.start_time)}</div>
          <div>{formatDateAndTime(row.end_time)}</div>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ color: 'black' }}
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit  >
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 150px', rowGap: '6px', fontSize: '10px' }}>
              <div>Total Score: {row.total_questions}</div>
              <div>Earned Score: {row.total_correct}</div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const BattleReportMobile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const [battles, setBattles] = useState([]);
  const [skills, setSkills] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [token, setToken] = useLocalStorage('token', null);
  const [filter, setFilter] = useState("");
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedBattle, setSelectedBattle] = useState("");
  const [reports, setReports] = useState([]);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(10);

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const fetchReports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/battle-report`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setReports(response.data.data);
      } else {
        console.error("Error fetching reports:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const handleExportClick = async () => {
    if (!selectedSkill) {
      toast.error("No skill selected");
      return
    }
    console.log(filter, selectedBattle, selectedSkill, selectedStage, " Exporting file");
    // fetchReports();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/export-battle`,
        {
          name: filter,
          battle_id: selectedBattle,
          skill_id: selectedSkill,
          stage_id: selectedStage
        },
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "BattleAttempts.xlsx");
      document.body.appendChild(link);
      link.click();
      console.log("File exported successfully");
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };

  const handleSkillChange = (event) => {
    const selectedSkillId = event.target.value;
    setSelectedSkill(selectedSkillId);
    fetchStages(selectedSkillId);
  };

  const fetchBattles = async (stageId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/battle/all/${stageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setBattles(response.data.data);
      } else {
        console.error("Error fetching battles:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching battles:", error);
    }
  };

  const fetchStages = async (selectedSkill) => {
    try {
      const fetchedStages = await service.getStages(token, selectedSkill);
      setStages(fetchedStages);
    } catch (error) {
      console.error("Error fetching stages: ", error);
    }
  };

  const handleReset = () => {
    setReports([]);
    setFilter("");
    setSelectedSkill("");
    setSelectedBattle("");
    setSelectedStage("");
  };

  useEffect(() => {
    fetchSkills();
    // fetchReports();
  }, [selectedSkill, filter]);

  if(!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2>You are unauthorized to access this page</h2>
      </div>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to={'/profile'}>
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <h4 style={{ margin: '1.313rem 0.25rem' }}>Battle Report</h4>
      <div className="container-fluid">
        <div className="align-center-all full-height">
          <Card style={{ boxShadow: 'none', padding: '0' }}>
            <CardContent style={{ padding: '0' }}>
              <div className="filters" style={{ marginBottom: '3.75rem' }}>
                <div>
                  <div className="col-md-3 mt-1">
                    <FormControl fullWidth sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                      marginTop: '22px',
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label" style={{ color: "#6c6c6c" }}>Skills</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={skills.length > 0 ? selectedSkill : ''}
                        onChange={handleSkillChange}
                        label="Skills"
                      >
                        {skills.length == 0 && <MenuItem value="">None</MenuItem>}
                        {skills?.map((skill) => (
                          <MenuItem key={skill.skill_id} value={skill.skill_id}>
                            {skill.skill_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                      marginTop: '22px',
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label" style={{ color: "#6c6c6c" }}>Stages</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={selectedStage}
                        onChange={(e) => {
                          const selectedStageId = e.target.value;
                          setSelectedStage(selectedStageId);
                          fetchBattles(selectedSkill, selectedStageId);
                        }}
                      >
                        {!stages.stages && <MenuItem value="">None</MenuItem>}
                        {Array.isArray(stages.stages) &&
                          stages.stages?.map((stage) => (
                            <MenuItem key={stage.stage_id} value={stage.stage_id}>
                              {stage.stage_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                      marginTop: '22px',
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label" style={{ color: "#6c6c6c" }}>Battle</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        // multiple
                        value={selectedBattle}
                        onChange={(e) => {
                          const selectedBattleId = e.target.value;
                          setSelectedBattle(selectedBattleId);
                        }}
                      >
                        {battles.length == 0 && <MenuItem value="">None</MenuItem>}
                        {battles?.map((battle) => (
                          <MenuItem key={battle.battle_id} value={battle.battle_id}>
                            {battle.battle_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* <FormControl fullWidth>
                      <InputLabel id="battle-label">Battle</InputLabel>
                      <Select
                        labelId="battle-label"
                        id="battle-select"
                        label="Battle"
                        onChange={(e) => {
                          const selectedBattleId = e.target.value;
                          setSelectedBattle(selectedBattleId);
                        }}
                        value={selectedBattle}
                      >
                        <MenuItem value="">None</MenuItem>
                        {battles.map((battle) => (
                          <MenuItem
                            key={battle.battle_id}
                            value={battle.battle_id}
                          >
                            {battle.battle_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}

                    <TextField
                      className="mt-3"
                      label="Name"
                      variant="outlined"
                      value={filter}
                      fullWidth
                      onChange={handleInputChange}
                      placeholder="Enter Name"
                      sx={{
                        width: '100%', '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            width: '100%',
                            boxShadow: 'none',
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                        required: false
                      }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}
                    />
                    <div className="mt-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Button
                        fullWidth
                        color="error"
                        style={{ flex: 1 }}
                        variant="contained"
                        className="mr-1 button-two cancelMobileButtonStyles"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                      <Button
                        fullWidth
                        style={{ flex: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={handleExportClick}
                        className="button-two addMobileButtonSytles"
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              {/* MUI Table */}
              {
                reports.length > 0 && (
                  <Table aria-label="collapsible table" sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none"
                    }
                  }}>
                    <TableHead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
                      <TableRow>
                        <TableCell>Name & Class</TableCell>
                        <TableCell align="right">Start Time/End Time</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reports
                        ?.filter(
                          (report) =>
                            (!filter ||
                              report.name
                                ?.toLowerCase()
                                .includes(filter?.toLowerCase()) ||
                              filter === "") &&
                            (!selectedBattle ||
                              report.battle_id
                                ?.toString()
                                .includes(selectedBattle) ||
                              selectedBattle === "") &&
                            (!selectedSkill ||
                              report.skill_id
                                ?.toString()
                                .includes(selectedSkill) ||
                              selectedSkill === "") &&
                            (!selectedStage ||
                              report.stage_id
                                ?.toString()
                                .includes(selectedStage) ||
                              selectedStage === "")
                        )
                        ?.map((filteredReport) => (
                          <Row key={filteredReport.attempt_id} row={filteredReport} />
                        ))}
                    </TableBody>
                  </Table>
                )
              }
            </CardContent>
          </Card>
        </div>
      </div>
      <Link to={'/report'}>
        <ArrowCircleLeftIcon sx={{ fill: '#37446f' }} style={{ position: 'fixed', bottom: '15px', right: '15px', cursor: 'pointer', marginTop: '1.25rem' }} />
      </Link>
    </>
  );
};
export default BattleReportMobile;