import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TableCell,
    TablePagination,
    TableRow,
    TextField,
    Select,
    MenuItem
} from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styles from "./LabsSeo.module.css";
import axios from "axios";
import TextEditor from "../Components/TextEditor/TextEditor";
import DeleteDialog from "../Components/ConfirmationDialog/DeleteDialog";
import isDesktop from "../isDesktop";

const LabsSeo = () => {
    const [isOpenCoreTest, setIsOpenCoreTest] = useState(false);
    const [isOpenTestSeries, setIsOpenTestSeries] = useState(false);
    const [isAdding, setIsAdding] = useState(true);
    const [labsData, setLabsData] = useState([]);
    const [subTitle, setSubTitle] = useState([]);
    const [selectedLabsId, setSelectedLabsId] = useState("");
    const [category, setCategory] = useState("core-test");
    const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
    const isAuthorized = userTabIds.includes(26);
    /* Delete Dialog Box */
  const [selectedItemForDelete, setSelectedItemForDelete] = useState(null);
  const [openDelDialog, setOpenDelDialog] = useState(false);
  const handleDeleteDialog = () => {
    setOpenDelDialog(true);
  };
    const openAddDialog = () => {
        setIsAdding(true);
        setCategory("core-test");
        setLabsDetails({
            title: "",
            labsOrder: "",
            imageURL: "",
            detailedDescription: "",
            shortDescription: "",
            keyTakeaways: "",
        });
        setSubTitle([]);
        setIsOpenCoreTest(true);
    };
    
    const openAddTestSeriesDialog = () => {
        setIsAdding(true);
        setCategory("test-series");
        setLabsDetails({
            title: "",
            labsOrder: "",
            imageURL: "",
            detailedDescription: "",
            shortDescription: "",
            keyTakeaways: "",
        });
        setSubTitle([]);
        setIsOpenTestSeries(true);
    };
    
    const [labsDetails, setLabsDetails] = useState({
        title: "",
        labsOrder: "",
        imageURL: "",
        detailedDescription: "",
        shortDescription: "",
        keyTakeaways: "",
    });
    
    const getAllLabsData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs-seo/labs/all`
            );
            if (response.status === 200) {
                setLabsData(response.data.data);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };
    
    const addLabsItem = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs-seo/labs`,
                {
                    title: labsDetails.title,
                    labsOrder: labsDetails.labsOrder,
                    imageURL: labsDetails.imageURL,
                    detailedDescription: labsDetails.detailedDescription,
                    shortDescription: labsDetails.shortDescription,
                    keyTakeaways: labsDetails.keyTakeaways,
                    subtitleTitles: subTitle.map(sub => sub.title),
                    subtitleDescriptions: subTitle.map(sub => sub.description),
                    category: category,
                }
            );
    
            if (response.status === 200) {
                toast.success("Labs item added successfully");
                getAllLabsData();
                handleCloseDialog();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };
    
    const editLabsItem = async (id, itemCategory) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs-seo/labs/${id}`);
            console.log(itemCategory);
    
            const data = response.data.data;
            setLabsDetails({
                title: data.title,
                labsOrder: data.labs_order,
                imageURL: data.image_url,
                detailedDescription: data.detailed_description || "",
                shortDescription: data.short_description || "",
                keyTakeaways: data.key_takeaways || "",
                category: itemCategory,
            });
    
            setSelectedLabsId(data.labs_id);
            let temp = [];
            if (response.data.data.subtitle_titles) {
                const descriptionData = JSON.parse(response.data.data.subtitle_descriptions);
                temp = JSON.parse(response.data.data.subtitle_titles).map((val, ind) => ({
                    title: val,
                    description: descriptionData[ind],
                }));
            }
    
            setSubTitle(temp);
            setCategory(itemCategory);
            setIsAdding(false);
            console.log("Editing lab item:", id, "Category:", itemCategory);
            console.log("Setting labs details:", labsDetails);
            console.log("Opening dialog for category:", itemCategory);
            if (itemCategory === "core-test") {
                setIsOpenCoreTest(true);
            } else if (itemCategory === "test-series") {
                setIsOpenTestSeries(true);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };
    
    const updateLabsItem = async () => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs-seo/labs/${selectedLabsId}`,
                {
                    title: labsDetails.title,
                    labsOrder: labsDetails.labsOrder,
                    imageURL: labsDetails.imageURL,
                    detailedDescription: labsDetails.detailedDescription,
                    shortDescription: labsDetails.shortDescription,
                    keyTakeaways: labsDetails.keyTakeaways,
                    subtitleTitles: subTitle.map(sub => sub.title),
                    subtitleDescriptions: subTitle.map(sub => sub.description),
                    category: category,
                }
            );
    
            if (response.status === 200) {
                toast.success("Labs item updated successfully");
                getAllLabsData();
                handleCloseDialog();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };
    
    const deleteLabsItem = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs-seo/labs/${id}`);
            if (response.status === 200) {
                setOpenDelDialog(false);
                toast.success("Labs item deleted successfully");
                getAllLabsData();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };
    
    const handleCloseDialog = () => {
        setSelectedLabsId("");
        setIsOpenCoreTest(false);
        setIsOpenTestSeries(false);
    };
    
    const onInputChange = (e) => {
        const { name, value } = e.target;
        setLabsDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };
    
    useEffect(() => {
        getAllLabsData();
    }, []);
    
    const addSubtitle = () => {
        setSubTitle((prev) => [...prev, { title: "", description: "" }]);
    };
    
    const onSubtitleChange = (e, index) => {
        setSubTitle((prev) => {
            const temp = [...prev];
            temp[index].title = e.target.value;
            return temp;
        });
    };
    
    const onSubDescriptionChange = (value, index) => {
        setSubTitle((prev) => {
            const temp = [...prev];
            temp[index].description = value;
            return temp;
        });
    };
    
    const onDetailedDescriptionChange = (value) => {
        setLabsDetails((prev) => ({ ...prev, detailedDescription: value }));
    };
    
    const onShortDescriptionChange = (value) => {
        setLabsDetails((prev) => ({ ...prev, shortDescription: value }));
    };
    
    const onSummaryChange = (value) => {
        setLabsDetails((prev) => ({ ...prev, keyTakeaways: value }));
    };
    
    const onDeleteSubtitle = (index) => {
        setSubTitle((prev) => prev.filter((_, ind) => ind !== index));
    };
    

    const isDesktopDevice = isDesktop();

    if(!isAuthorized) {
        return (
          <div className="container-fluid mt-5">
            <h2 style={{marginTop: '2rem'}}>You are unauthorized to access this page</h2>
          </div>
        )
      }

    return (
        <>
            {
                isDesktopDevice ? (
                    <>
                        <div>
                            <div style={{ paddingTop: '5vw' }}>
                                <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                                    <CardContent>
                                        <div className={styles.header}>
                                            <h2 style={{ marginRight: "1rem" }} className="page-header">
                                                Core Test
                                            </h2>
                                            <div className={styles.headerRight}>
                                                <Button
                                                    style={{
                                                        width: "15.385vw",
                                                        height: "2.564vw",
                                                        padding: "1.026vw 2.308vw",
                                                        gap: "0.641vw",
                                                        borderRadius: "4px",
                                                        color: "#ffffff",
                                                        fontSize: "0.897vw",
                                                        backgroundColor: "#37446F",
                                                        boxShadow: "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                                    }}
                                                    onClick={openAddDialog}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                        </div>
                                        <table className={styles.mainTable}>
                                            <thead className={styles.tableHead}>
                                                <tr>
                                                    <th style={{ textAlign: "left", paddingLeft: "2vw", width: "20%" }}>S. No.</th>
                                                    <th style={{ width: "50%", textAlign: "left" }}>Name</th>
                                                    <th style={{ textAlign: "left", paddingLeft: "5.128vw", width: "fit-content" }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {labsData
                                                    .filter((lab) => lab.category === "core-test")
                                                    .map((lab, index) => (
                                                        <tr key={lab.labs_id} className={styles.tableBody}>
                                                            <td style={{ textAlign: "left", paddingLeft: "3vw", width: "20%" }}>{index + 1}</td>
                                                            <td style={{ textAlign: "left" }}>{lab.title}</td>
                                                            <td style={{ paddingLeft: "5.128vw" }}>
                                                                <div className={styles.buttonContainer}>
                                                                    <Button
                                                                        variant="contained"
                                                                        fullWidth
                                                                        onClick={() => editLabsItem(lab.labs_id, "core-test")}
                                                                        style={{
                                                                            backgroundColor: "#D1F4DE",
                                                                            height: "2.051vw",
                                                                            width: "7.179vw",
                                                                            color: "black",
                                                                            textTransform: "capitalize",
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        fullWidth
                                                                        onClick={() => {setSelectedItemForDelete(lab.labs_id); handleDeleteDialog();}}
                                                                        style={{
                                                                            backgroundColor: "#FFD6D2",
                                                                            height: "2.051vw",
                                                                            width: "7.179vw",
                                                                            color: "black",
                                                                            textTransform: "capitalize",
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </CardContent>
                                </Card>
                            </div>
                            <Dialog
                                open={isOpenCoreTest}
                                onClose={() => {setIsOpenCoreTest(false)}}
                                PaperProps={{
                                    style: {
                                        width: "auto",
                                        maxWidth: "100vw",
                                        height: "35.128vw",
                                        padding: "1.5rem",
                                        borderRadius: "0.513vw",
                                        boxShadow: "4px 4px 8px 0px #AEAEC099, -4px -4px 8px 0px #FFFFFF80",
                                    },
                                }}
                            >
                                <DialogTitle
                                    style={{
                                        paddingLeft: "0",
                                        fontWeight: "600",
                                        paddingTop: "0",
                                    }}
                                >
                                    {isAdding ? "Add New Core Test" : "Update Core Test"}
                                </DialogTitle>
                                <DialogContent style={{ paddingTop: "1vw" }}>
                                    <div className={styles.dialogContentContainer}>
                                        <TextField
                                            label="Category"
                                            value="core-test"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            style={{ marginBottom: '1rem' }}
                                        />
                                        <TextField
                                            type="text"
                                            label="Lab Name"
                                            value={labsDetails.title}
                                            name="title"
                                            onChange={onInputChange}
                                        />
                                        <TextField
                                            type="text"
                                            name="labsOrder"
                                            label="Lab Order"
                                            onChange={onInputChange}
                                            value={labsDetails.labsOrder}
                                        />
                                        <TextField
                                            type="text"
                                            name="imageURL"
                                            label="Image URL"
                                            onChange={onInputChange}
                                            value={labsDetails.imageURL}
                                        />
                                        <div className={styles.shortDescriptionContainer}>
                                            <TextEditor
                                                propsContent={labsDetails.shortDescription}
                                                propPlaceholder="Short Description..."
                                                onChangeContent={onShortDescriptionChange}
                                            />
                                        </div>
                                        {/* <div className={styles.descriptionContainer}>
                                            <TextEditor
                                                propsContent={learnDetails.detailedDescription}
                                                propPlaceholder="Detailed Description..."
                                                onChangeContent={onDetailedDescriptionChange}
                                            />
                                        </div>
                                        <div className={styles.summaryContainer}>
                                            <TextEditor
                                                propsContent={learnDetails.keyTakeaways}
                                                propPlaceholder="key takeaways"
                                                onChangeContent={onSummaryChange}
                                            />
                                        </div> */}
                                        {subTitle.map((sub, index) => (
                                            <div key={index} className={styles.subtitleContainer}>
                                                <TextField
                                                    type="text"
                                                    name="title"
                                                    label="Subtitle Title"
                                                    value={sub.title}
                                                    onChange={(e) => onSubtitleChange(e, index)}
                                                />
                                                <div className={styles.subDescriptionContainer}>
                                                    <TextEditor
                                                        key={index}
                                                        index={index}
                                                        propsContent={sub.description}
                                                        propPlaceholder="Subtitle Description..."
                                                        onChangeContent={onSubDescriptionChange}
                                                    />
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={() => onDeleteSubtitle(index)}
                                                    style={{
                                                        backgroundColor: "#FFD6D2",
                                                        height: "2.051vw",
                                                        width: "7.179vw",
                                                        color: "black",
                                                        marginTop: "1vw",
                                                        textTransform: "capitalize",
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        ))}
                                        <Button
                                            style={{
                                                width: "15.385vw",
                                                height: "2.564vw",
                                                padding: "1.026vw 2.308vw",
                                                gap: "0.641vw",
                                                borderRadius: "4px",
                                                color: "#ffffff",
                                                fontSize: "0.897vw",
                                                backgroundColor: "#37446F",
                                                gridColumn: "1/2",
                                                boxShadow:
                                                    "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                            }}
                                            onClick={addSubtitle}
                                        >
                                            Add Subtitle
                                        </Button>
                                    </div>
                                    <div className={styles.lowerContainer}>
                                        <Button
                                            style={{
                                                width: "15.385vw",
                                                height: "2.564vw",
                                                padding: "1.026vw 2.308vw",
                                                gap: "0.641vw",
                                                borderRadius: "4px",
                                                color: "#000000",
                                                border: "2.03px solid #313C63",
                                                fontSize: "0.897vw",
                                                backgroundColor: "#ffffff",
                                                boxShadow:
                                                    "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                            }}
                                            onClick={handleCloseDialog}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            style={{
                                                width: "15.385vw",
                                                height: "2.564vw",
                                                padding: "1.026vw 2.308vw",
                                                gap: "0.641vw",
                                                borderRadius: "4px",
                                                color: "#ffffff",
                                                fontSize: "0.897vw",
                                                backgroundColor: "#37446F",
                                                boxShadow:
                                                    "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                            }}
                                            onClick={() => {
                                                isAdding ? addLabsItem() : updateLabsItem();
                                            }}
                                        >
                                            {isAdding ? "Add" : "Update"}
                                        </Button>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div>
                        <div>
                            <div style={{ paddingTop: '5vw' }}>
                                <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                                    <CardContent>
                                        <div className={styles.header}>
                                            <h2 style={{ marginRight: "1rem" }} className="page-header">
                                                Test Series
                                            </h2>
                                            <div className={styles.headerRight}>
                                                <Button
                                                    style={{
                                                        width: "15.385vw",
                                                        height: "2.564vw",
                                                        padding: "1.026vw 2.308vw",
                                                        gap: "0.641vw",
                                                        borderRadius: "4px",
                                                        color: "#ffffff",
                                                        fontSize: "0.897vw",
                                                        backgroundColor: "#37446F",
                                                        boxShadow: "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                                    }}
                                                    onClick={openAddTestSeriesDialog}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                        </div>
                                        <table className={styles.mainTable}>
                                            <thead className={styles.tableHead}>
                                                <tr>
                                                    <th style={{ textAlign: "left", paddingLeft: "2vw", width: "20%" }}>S. No.</th>
                                                    <th style={{ width: "50%", textAlign: "left" }}>Name</th>
                                                    <th style={{ textAlign: "left", paddingLeft: "5.128vw", width: "fit-content" }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {labsData
                                                    .filter((lab) => lab.category === "test-series")
                                                    .map((lab, index) => (
                                                        <tr key={lab.labs_id} className={styles.tableBody}>
                                                            <td style={{ textAlign: "left", paddingLeft: "3vw", width: "20%" }}>{index + 1}</td>
                                                            <td style={{ textAlign: "left" }}>{lab.title}</td>
                                                            <td style={{ paddingLeft: "5.128vw" }}>
                                                                <div className={styles.buttonContainer}>
                                                                    <Button
                                                                        variant="contained"
                                                                        fullWidth
                                                                        onClick={() => editLabsItem(lab.labs_id, "test-series")}
                                                                        style={{
                                                                            backgroundColor: "#D1F4DE",
                                                                            height: "2.051vw",
                                                                            width: "7.179vw",
                                                                            color: "black",
                                                                            textTransform: "capitalize",
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        fullWidth
                                                                        onClick={() => {setSelectedItemForDelete(lab.labs_id); handleDeleteDialog();}}
                                                                        style={{
                                                                            backgroundColor: "#FFD6D2",
                                                                            height: "2.051vw",
                                                                            width: "7.179vw",
                                                                            color: "black",
                                                                            textTransform: "capitalize",
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        <DeleteDialog 
                                            open={openDelDialog} 
                                            onClose={() => setOpenDelDialog(false)} 
                                            onDelete={() => deleteLabsItem(selectedItemForDelete)} 
                                            category={selectedItemForDelete}
                                        />
                                    </CardContent>
                                </Card>
                                <Dialog
                                    open={isOpenTestSeries}
                                    onClose={() => setIsOpenTestSeries(false)}
                                    PaperProps={{
                                        style: {
                                            width: "auto",
                                            maxWidth: "100vw",
                                            height: "35.128vw",
                                            padding: "1.5rem",
                                            borderRadius: "0.513vw",
                                            boxShadow:
                                                "4px 4px 8px 0px #AEAEC099, -4px -4px 8px 0px #FFFFFF80",
                                        },
                                    }}
                                >
                                    <DialogTitle
                                        style={{
                                            paddingLeft: "0",
                                            fontWeight: "600",
                                            paddingTop: "0",
                                        }}
                                    >
                                        {isAdding ? "Add New Test Series" : "Update Test Series"}
                                    </DialogTitle>
                                    <DialogContent style={{ paddingTop: "1vw" }}>
                                        <div className={styles.dialogContentContainer}>
                                            <TextField
                                                label="Category"
                                                value="test-series"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                style={{ marginBottom: '1rem' }}
                                            />
                                            <TextField
                                                type="text"
                                                label="Lab Name"
                                                value={labsDetails.title}
                                                name="title"
                                                onChange={onInputChange}
                                            />
                                            <TextField
                                                type="text"
                                                name="labsOrder"
                                                label="Lab Order"
                                                onChange={onInputChange}
                                                value={labsDetails.labsOrder}
                                            />
                                            <TextField
                                                type="text"
                                                name="imageURL"
                                                label="Image URL"
                                                onChange={onInputChange}
                                                value={labsDetails.imageURL}
                                            />
                                            <div className={styles.shortDescriptionContainer}>
                                                <TextEditor
                                                    propsContent={labsDetails.shortDescription}
                                                    propPlaceholder="Short Description..."
                                                    onChangeContent={onShortDescriptionChange}
                                                />
                                            </div>
                                            {/* <div className={styles.descriptionContainer}>
                                                <TextEditor
                                                    propsContent={learnDetails.detailedDescription}
                                                    propPlaceholder="Detailed Description..."
                                                    onChangeContent={onDetailedDescriptionChange}
                                                />
                                            </div>
                                            <div className={styles.summaryContainer}>
                                                <TextEditor
                                                    propsContent={learnDetails.keyTakeaways}
                                                    propPlaceholder="key takeaways"
                                                    onChangeContent={onSummaryChange}
                                                />
                                            </div> */}
                                            {subTitle.map((sub, index) => (
                                                <div key={index} className={styles.subtitleContainer}>
                                                    <TextField
                                                        type="text"
                                                        name="title"
                                                        label="Subtitle Title"
                                                        value={sub.title}
                                                        onChange={(e) => onSubtitleChange(e, index)}
                                                    />
                                                    <div className={styles.subDescriptionContainer}>
                                                        <TextEditor
                                                            key={index}
                                                            index={index}
                                                            propsContent={sub.description}
                                                            propPlaceholder="Subtitle Description..."
                                                            onChangeContent={onSubDescriptionChange}
                                                        />
                                                    </div>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        onClick={() => onDeleteSubtitle(index)}
                                                        style={{
                                                            backgroundColor: "#FFD6D2",
                                                            height: "2.051vw",
                                                            width: "7.179vw",
                                                            color: "black",
                                                            marginTop: "1vw",
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            ))}
                                            <Button
                                                style={{
                                                    width: "15.385vw",
                                                    height: "2.564vw",
                                                    padding: "1.026vw 2.308vw",
                                                    gap: "0.641vw",
                                                    borderRadius: "4px",
                                                    color: "#ffffff",
                                                    fontSize: "0.897vw",
                                                    backgroundColor: "#37446F",
                                                    gridColumn: "1/2",
                                                    boxShadow:
                                                        "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                                }}
                                                onClick={addSubtitle}
                                            >
                                                Add Subtitle
                                            </Button>
                                        </div>
                                        <div className={styles.lowerContainer}>
                                            <Button
                                                style={{
                                                    width: "15.385vw",
                                                    height: "2.564vw",
                                                    padding: "1.026vw 2.308vw",
                                                    gap: "0.641vw",
                                                    borderRadius: "4px",
                                                    color: "#000000",
                                                    border: "2.03px solid #313C63",
                                                    fontSize: "0.897vw",
                                                    backgroundColor: "#ffffff",
                                                    boxShadow:
                                                        "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                                }}
                                                onClick={handleCloseDialog}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                style={{
                                                    width: "15.385vw",
                                                    height: "2.564vw",
                                                    padding: "1.026vw 2.308vw",
                                                    gap: "0.641vw",
                                                    borderRadius: "4px",
                                                    color: "#ffffff",
                                                    fontSize: "0.897vw",
                                                    backgroundColor: "#37446F",
                                                    boxShadow:
                                                        "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                                }}
                                                onClick={() => {
                                                    isAdding ? addLabsItem() : updateLabsItem();
                                                }}
                                            >
                                                {isAdding ? "Add" : "Update"}
                                            </Button>
                                        </div>
                                    </DialogContent>
                                </Dialog>

                            </div>
                        </div>

                    </>

                ) : (
                    <div>
                        Hello
                    </div>
                )
            }
        </>
    );
};

export default LabsSeo;